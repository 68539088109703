import React, { useEffect, useState, useRef} from 'react';
import { useParams } from "react-router-dom"
import Tree from './tree.js'
export default function Styleguide() {

    const [tree, setTree] = useState([]);
    const [loading, setLoading] = useState(false);
    const [authentification, setAuthentification] = useState('');
    const authentificationRef = useRef('');
    const [ready, setReady] = useState('');
    const [error, setError] = useState('');
    const [oldStyleguideId, setOldStyleguideId] = useState('');
    const [lHeaders, setLHeaders] = useState(new Headers());

    let { styleguideId } = useParams();
    let warning = ""

    useEffect(() => {
        if(styleguideId !== oldStyleguideId){
            setOldStyleguideId(styleguideId);
            setError("");
        }
        if (ready === styleguideId) {
            setLoading(true);

            let headers = new Headers();
            headers.append('Authorization', 'Basic ' + btoa(styleguideId + ":" + authentification));
            setLHeaders(headers);

            try {
                fetch('/api/guides/' + styleguideId, { method: 'GET', headers: headers })
                    .then(response => {
                        if (response.status === 200) {
                            response.json()
                                .then(data => {
                                    setError("")
                                    setTree(data);
                                    setLoading(false);
                                });
                        } else {
                            setLoading(false);
                            setReady("")
                            setError("Die Anmeldunge war nicht korrekt.")
                        }
                    })
            } catch (error) {
                console.log('Fehler beim Abrufen der Daten.', error);
            }
        } else  {
            setReady("")
        }
        if(authentificationRef != null && authentificationRef.current != null) {
            authentificationRef.current.value = '';
        }
        setAuthentification("")
    }, [styleguideId, ready, authentificationRef]);

    const handleKeyPress = (event) => {
        if (event.keyCode === 13 || event.which === 13) {
            setReady(styleguideId);
        }
    }

    if(error != null && error !== ""){
        warning =  <div className="m-2 alert alert-danger d-flex align-items-center" role="alert">{error}</div>
    }


    if (loading) {

        return <div className="card"  id="styleguide">
            <div className="card-header">
                <p>Lade Styleguide {styleguideId}</p>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        </div>
    }

    if (ready !== styleguideId) {
        return <div className="card" id="styleguide">
            <div className="card-header">
                <h2>{styleguideId}</h2>
            </div>        
            {warning}
            <div className="card-body">
                <div className="mb-3 d-flex flex-column">
                    <label htmlFor="login" className="form-label align-self-start">Passwort:</label>
                    <form>
                        <input ref={authentificationRef} id="login" className="form-control align-self-start" type="password" name="login" value={authentification} onChange={event => { setAuthentification(event.target.value) }} onKeyDown = {event => { handleKeyPress(event) }}/>
                    </form>
                    <small id="emailHelp" className="form-text align-self-start">Bitte geben sie das Passwort für diesen Styleguide an.</small>                    
                    </div>
                <div className="d-flex flex-column">
                    <button type="button" className="btn btn-primary align-self-start" onClick={event => { setReady(styleguideId) }}>Anmelden</button>
                </div>
                
            </div>
        </div>
    }


    return (
        <div className="card" id="styleguide">
            <div className="card-header">
                <h2>{styleguideId}</h2>
            </div>
            <div className="card-body">
                <Tree rootName={styleguideId} treeData={tree} headers={lHeaders} />
            </div>
        </div>
    )
}