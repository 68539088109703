import './App.css';
import "./bootstrap-kosmos.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import StyleguideList from './components/styleguidelist.js'
import Styleguide from './components/styleguide.js'
import logo from './logo.png'

function App() {

  return (
    <BrowserRouter>
      <div className="App container-fluid p-2">
          <div className="row">
            <div className="col-md-auto">
              <img src={logo} className="App-logo" alt="logo" />
              </div>
              <div className="col-md-auto">
            <h1>Kosmos Styleguides</h1> 
            </div>
          </div>
          <div className="row">
            <StyleguideList />
            <div className="col-md-9" id="detail">
              <Routes>
                <Route path="/styleguide/:styleguideId" Component={Styleguide} />
              </Routes>
            </div>
          </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
