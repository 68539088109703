import { createRef } from 'react'

export default function Download({url, rootName, name, headers}) {
    const link = createRef();

    const handleDownload = async () => {

        if (link.current.href) { return }
 
        await fetch(url, { method: 'GET', headers: headers}) 
        .then(response => response.json())
        .then(data => {
            let url = "/api/guides/"+rootName+"/file?token="+data.token;
            window.open(url, '_blank'); 
        })
  
    };

    return (
        <a style={{cursor: "pointer"}} className="link-primary" ref={link} onClick={handleDownload}>{name}</a>
    )
        
};