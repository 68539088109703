import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function StyleguideList() {

  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [selectedStylguide, setSelectedStyleguide] = useState("");
  const list = [];

  links.forEach((name) => {
    if (name.toLowerCase().indexOf(filter.toLocaleLowerCase()) >= 0) {
      let link = "/styleguide/" + name
      let classes = "list-group-item" + (name === selectedStylguide ? " active" : "");
      list.push(<li key={link} className={classes} onClick={() => { setSelectedStyleguide(name) }}><Link overlay="true" className={classes} style={{ display: 'contents' }} to={link} underline="none"><div>{name}</div></Link></li>)
    }
  })

  useEffect(() => {
    setLoading(true);

    fetch('/api/guides')
      .then(response => response.json())
      .then(data => {
        setLinks(data);
        setLoading(false);
      })
  }, []);

  if (loading) {
    return <div>
      <p>Lade Kosmos Styleguides</p>
      <div id="sidebar" className='col-md-3 card container'>
        <div className="card-header"></div>
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    </div>
  }

  return (
    <div className="col-md-3">
      <div id="sidebar" className='card'>
        <div className="card-header">
          <div><input id="sucheInp" className="w-100" placeholder="Suche" type="search" name="Suche" onChange={event => { setFilter(event.target.value) }} /></div>
        </div>
        <div className="card-body">
          <ul className="list-group">
            {list}
          </ul>
        </div>
      </div>
    </div>
  )
}