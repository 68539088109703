import TreeNode from "./treenode";

export default function Tree({rootName, treeData, headers}) {
    return (
        <ul>
            {treeData.map((node) => (
                <TreeNode rootName={rootName} node={node} key={node.key} headers={headers}/>
            ))}
        </ul>
    );
}