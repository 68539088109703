import React, {useState } from 'react';
import Tree from './tree.js'
import Download from './download.js'

export default function TreeNode({rootName, node, headers}) {

    const {nodes, name } = node;
    const [showChildren, setShowChildren] = useState(true);
    
    const handleClick = () => {
        setShowChildren(!showChildren);
    };

    let url = "/api/guides/"+rootName+"/token?key="+node.key;

    if(node.nodeType === 'FILE'){
        return  <li style={{"listStyle": "none"}}>
                    <div style={{ textAlign: "left", marginBottom: "10px" }}>
                        <i className="bi-file-earmark-code"/>
                        <Download url={url} rootName={rootName} name={name} headers={headers}/>
                    </div>
                </li>
    }

    return (
        <li style={{listStyle: "none"}}>
            <div onClick={handleClick} style={{ textAlign: "left", marginBottom: "10px" }}>
                {showChildren ? <i className="bi bi-folder2-open mr-1"/> : <i className="bi bi-folder"/>}
                <span> {name}</span>
            </div>
            <ul style={{ paddingLeft: "10px" }}>
                {showChildren && <Tree rootName={rootName} treeData={nodes} headers={headers}/>}
            </ul>
        </li>
    );
}